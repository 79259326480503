/* Ferry Black */
@font-face {
  font-family: 'Ferry';
  font-style: normal;
  font-weight: 900;
  src: local('Ferry Black'), local('Ferry-Black'),
    url('../assets/fonts/ferry-black.woff2') format('woff2'),
    url('../assets/fonts/ferry-black.woff') format('woff');
  font-display: swap;
}

/* inter-regular - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/inter-v12-latin_cyrillic-regular.woff2')
      format('woff2'),
    url('../assets/fonts/inter-v12-latin_cyrillic-regular.woff') format('woff');
  font-display: swap;
}

/* inter-500 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('../assets/fonts/inter-v12-latin_cyrillic-500.woff2') format('woff2'),
    url('../assets/fonts/inter-v12-latin_cyrillic-500.woff') format('woff');
  font-display: swap;
}

/* inter-700 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../assets/fonts/inter-v12-latin_cyrillic-700.woff2') format('woff2'),
    url('../assets/fonts/inter-v12-latin_cyrillic-700.woff') format('woff');
  font-display: swap;
}
