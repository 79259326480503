@use '../../scss/variables' as v;
@use '../../scss/include-media' as *;

.wrap {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: v.$gap;

  @include media('>=md') {
    grid-template-columns: 1fr 1fr;
  }

  label {
    @include media('>=md') {
      grid-column: 1 / 3;
    }
  }
}

.datePickerWrap {
  @include media('>=md') {
    grid-row: 2 / 3;
  }
}

.datePickerWrap ~ .datePickerWrap {
  grid-row: 3 / 4;
  margin-top: 2rem;

  @include media('>=md') {
    grid-row: 2 / 3;
    margin-top: 0;
  }
}
