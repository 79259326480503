// Fonts
$font-family: 'Inter', sans-serif;
$font-family-title: 'Ferry', sans-serif;

$body-font: $font-family;
$body-font-weight: 400;

$header-font: $font-family-title;
$header-font-weight: 900;

// For REM / EM
$basic-font-size: 62.5%;
$paragraph: 1.8rem;
$line-height: 2.2rem;

// Basic colors
$blue: #00f;
$red: #f00;
$white: #fff;
$black: #000;
$gray: #d2d2d2;

// Palette
$primary: #5970ff;
$secondary: #7ce3e1;
$accent: #ffb64f;
$accent-2: #029491;
$light: $white;
$dark: $black;
$danger: #ff5959;

// Mixing colors
$text-color: $dark;
$inverted-text-color: $light;
$link-color: $primary;

$default-btn-text-color: $text-color;
$default-btn-bg-color: $gray;
$primary-btn-text-color: $inverted-text-color;
$primary-btn-bg-color: $primary;
$secondary-btn-text-color: $text-color;
$secondary-btn-bg-color: $secondary;

// Blocks
$container-max-width: 136rem;
$margin: 2rem;
$padding: 2rem;
$section-padding-pc: 5rem 2rem;
$section-padding-mobile: 2.5rem 2rem;
$gap: 2rem;
$bd-radius: 0.5rem;

// Mobile menu breakpoint
$mobile-menu-breakpoint: 'lg';
