@use '../../scss/include-media' as *;

.inner {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;

  @include media('>=xs') {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    border-right: 2px solid #949494;
  }
}

.icon {
  width: 100%;
  max-width: 6.4rem;
  height: auto;
}

.text {
  width: 33%;
  text-align: center;

  @include media('>=xs') {
    width: 100%;
  }

  &:not(:last-child) {
    @include media('>=xs') {
      margin-bottom: 2.5rem;
    }
  }
}
