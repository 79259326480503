@use 'scss-reset/reset.scss';

@use './scss/fonts';
@use './scss/typography';

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
