@use '../../scss/include-media' as *;
@use '../../scss/variables' as v;

.limits {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  align-items: center;
  column-gap: 0.9rem;
  flex-grow: 1;
  max-width: 18rem;
  padding: 0.5rem 1rem;
  text-align: left;
  background-color: #f4f4f4;
  border-radius: 0.5rem;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    grid-template-columns: 1fr auto;
    justify-items: end;
    padding: 1.4rem 1rem;
    text-align: right;
  }
}

.spinner {
  grid-column: 1/ 2;
  grid-row: 1 / 5;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    grid-column: 1/ 3;
    grid-row: 1/ 3;
  }
}

.text {
  font-size: 0.8rem;
  line-height: 1rem;
  white-space: nowrap;
  opacity: 0.4;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.used,
.all {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.all {
  color: #8ac540;
}
