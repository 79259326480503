@use '../../scss/placeholders';
@use '../../scss/typography';
@use '../../scss/variables' as v;
@use '../../scss/include-media' as *;

.search {
  display: grid;
  grid-template-columns: 1fr;
  gap: v.$gap;
  @extend %container;
  @extend %section-padding;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    grid-template-columns: 2fr 1fr;
    justify-items: start;
    align-items: start;
  }

  &__text {
    background-image: url('../../assets/images/documents.svg');
    background-repeat: no-repeat;
    background-position: right -15rem center;
    background-size: 21rem;

    @include media('>=#{v.$mobile-menu-breakpoint}') {
      grid-column: 1 / 3;
      width: 100%;
      background-position: right bottom;
      background-size: 40rem;
    }
  }

  &__title {
    max-width: 70rem;
    @extend %h3;
  }

  &__desc {
    margin-top: 1.9rem;

    @include media('>=#{v.$mobile-menu-breakpoint}') {
      margin-top: 2.5rem;
    }
  }

  &__img {
    align-self: end;
    width: 100%;
    max-width: 44rem;
    height: auto;
  }

  &__form {
    @include media('>=#{v.$mobile-menu-breakpoint}') {
      grid-column: 1 / 2;
      margin-top: 2.7rem;
    }
  }
}
