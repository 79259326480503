@use '../../scss/variables' as v;
@use '../../scss/placeholders';
@use '../../scss/include-media' as *;

.main-slider {
  .slick-list {
    margin-left: 1rem;
    margin-right: 1rem;

    @include media('>=#{v.$mobile-menu-breakpoint}') {
      margin-left: 3.5rem;
      margin-right: 3.5rem;
    }
  }

  .slick-prev,
  .slick-next {
    width: 2.6rem;
    height: auto;
    opacity: 0.4;
    @extend %transition;

    &:hover {
      opacity: 1;
    }

    &:before {
      content: '';
    }
  }

  .slick-prev {
    left: -1rem;

    @include media('>=#{v.$mobile-menu-breakpoint}') {
      left: 0;
    }
  }

  .slick-next {
    right: -1rem;
    @include media('>=#{v.$mobile-menu-breakpoint}') {
      right: 0;
    }
  }

  // Begin equal height
  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex !important;
    height: auto;
  }

  .slick-slide div {
    display: flex !important;
  }
  // End equal height
}
