@use './variables' as v;
@use './include-media' as *;

%transition {
  transition: all 0.3s ease-out;
}

%container {
  width: 100%;
  max-width: v.$container-max-width;
  margin: 0 auto;
}

%row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  @extend %container;
}

%column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  @extend %container;
}

%section-padding {
  padding: v.$section-padding-mobile;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    padding: v.$section-padding-pc;
  }
}
