@use '../../scss/include-media' as *;

.loading {
  display: flex !important;
  justify-content: center;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;

  @include media('>=xs') {
    flex-direction: column;
    width: 20rem !important;
    padding-top: 3.7rem;
    padding-bottom: 3.6rem;
  }
}

.spinner {
  width: 3.6rem;

  @include media('>=xs') {
    width: 5rem;
    margin-bottom: 1.1rem;
  }
}

.text {
  display: none;

  @include media('>=xs') {
    display: block;
  }
}
