@use '../../scss/variables' as v;
@use '../../scss/include-media' as *;

.card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: v.$light;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    padding: 2rem 3rem 3.5rem;
  }
}

.meta {
  margin-bottom: 2.1rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.01em;
  color: #949494;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.02em;
  }
}

.date {
  margin-right: 1rem;
}

.source {
  color: #949494;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.title {
  margin-bottom: 1.4rem;
  font-family: v.$body-font;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 2.3rem;
  letter-spacing: 0.01em;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    font-size: 2.6rem;
    line-height: 3.1rem;
    letter-spacing: 0.02em;
  }
}

.tags {
  display: flex;
  margin-bottom: 1.4rem;
}

.tag {
  margin-right: 1rem;
  padding: 0.4rem 1.2rem;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.01em;
  background: v.$accent;
  border-radius: 0.5rem;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
  }
}

.image {
  width: 100%;
  height: 16rem;
  margin-bottom: 2rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1rem;
}

.content {
  margin-bottom: 3.2rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  opacity: 0.5;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.02em;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 1;
}

.readMore {
  margin-right: 2rem;
}

.wordCount {
  margin-left: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  white-space: nowrap;
  color: #949494;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    font-size: 1.6rem;
    line-height: 1.9rem;
    letter-spacing: 0.02em;
  }
}
